<template>
  <div class="hello">
    Copy and paste here:<br/>
    <textarea id="import" v-model="importData"></textarea>
    <hr/>
    <button @click="importer">Import</button>
    <hr/>
    <table>
      <tr v-for="row, rowIndex in rows" v-bind:key="row">
        <td v-for="col, index in row" v-bind:key="col">
          <input type="text" v-bind:value="col" @change="numberChanged(rowIndex, index, $event)"
    @blur="numberChanged(rowIndex, index, $event)"/>
        </td>
      </tr>
    </table>
    <hr/>
    <button @click="preview">Preview</button>
    <hr/>
    <textarea id="export" v-bind:value="exportData"></textarea>
    <hr/>
    <input type="text" v-model="rowCount"/>
    <input type="text" v-model="colCount"/>
    <button @click="createEmpty">Create Empty</button>
    </div>
</template>

<script>
import {unref } from 'vue';

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      rowCount: 4,
      colCount: 4,
      importData: "1:2:3:4:5:6:7:8:9:10|0:0:0:0:0:0:0:0:0:0|0:0:0:0:0:0:0:0:0:0|12:13:14:15:16:17:18:19:20:21|0:0:0:0:0:0:0:0:0:0|0:0:0:0:0:0:0:0:0:0|22:23:24:25:26:27:28:29:30:31|0:0:0:0:0:0:0:0:0:0|0:0:0:0:0:0:0:0:0:0|32:33:34:34:35:36:37:38:39:40",
      rows:[],
      exportData: ""
    }
  },
  methods: {
    numberChanged(rowIndex, index, event){
      console.log("nunberChanged() event=");
      console.log(event);
      console.log(rowIndex+", "+index+", "+event);
      console.log("event.currentTarget.value="+event.currentTarget.value);
      this.rows[rowIndex][index]=event.currentTarget.value;
      console.log("this.rows=");
      console.log(this.rows);
    },
    preview(){
      console.log("rows=");
      console.log(this.rows);
      let result=[];
      for(let n=0; n<this.rows.length; n++){
        let line=[];
        for(let m=0; m<this.rows[n].length; m++){
          line.push(this.rows[n][m]);
        }
        result.push(line.join(":"));
      }
      console.log("result=");
      console.log(unref(result));
      this.exportData=result.join("|");
    },
    importer(){
      console.log("import");
      let result=[];
      let rows=this.importData.split("|");
      console.log(this.rows);
      for(let n=0; n<rows.length; n++){
        result.push(rows[n].split(":"));
      }
      console.log("result=");
      console.log(result);
      this.rows=result;
    },
    createEmpty(){
      let data=[];
      for(let n=0; n<this.rowCount; n++){
        var line=[];
        for(let m=0; m<this.colCount; m++){
          line.push(0);
        }
        data.push(line.join(":"));
      }
      this.importData=data.join("|");
    },
    say(message) {
      alert(message)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
textarea{
  width: 80%;
  height: 200px;
}
</style>
